import serverAPI from '@/services/serverAPI'

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 */
const fetchNotificationsSettings = async ({ commit }) => {
  const response = await serverAPI.fetchNotificationsSettings()

  commit('setNotificationsSettings', response)
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 */
const resetNotificationSettings = async ({ commit }) => {
  const response = await serverAPI.resetNotificationSettings()

  commit('setNotificationsSettings', response)
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {object}       payload
 */
const updateNotificationsSettings = async ({ commit }, payload) => {
  const response = await serverAPI.updateNotificationsSettings(payload)
  commit('setNotificationsSettings', response)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const fetchNotifications = async (store, payload) => {
  const page = payload ? payload.page : 1
  const limit = 6
  const response = await serverAPI.fetchNotifications(page, limit)

  if (response.notifications.length <= response.count) {
    store.commit('setNotifications', response.notifications)
    store.commit('setUnseenNotificationsCount', response.unseen_count)
    store.commit('setNotificationsCount', response.count)
    store.commit('incrementNotificationsPage')
  }
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const readNotification = async (store, payload) => {
  await serverAPI.readNotification(payload.id)

  store.commit('setNotificationRead', payload.id)
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const changeBadgeVisibility = (store, payload) => {
  store.commit('setNotificationsBadgeVisibility', payload.visible)
}

/**
 * @param {Vuex.Store} store
 */
const markAllNotificationsSeen = async store => {
  await serverAPI.markAllNotificationsSeen()

  store.commit('setUnseenNotificationsCount', 0)
}

export default {
  fetchNotificationsSettings,
  updateNotificationsSettings,
  fetchNotifications,
  readNotification,
  changeBadgeVisibility,
  markAllNotificationsSeen,
  resetNotificationSettings
}
