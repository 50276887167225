<template>
  <div class="detail-header">
    <div class="detail-header__color-run">
      <StitchColorRun :images="itemColorRun" />
    </div>
    <div class="detail-header__info">
      <div class="detail-header__title">
        <StitchTooltip :message="item.name">
          <h1>{{ item.name }}</h1>
        </StitchTooltip>
        <div
          v-if="!isVendorUser"
          class="detail-header__actions"
        >
          <StitchTooltip message="Actions">
            <StitchDropdown
              :dropdown-items="criticalStatusTransitions"
              :append-to-body="false"
              class="detail-header__dropdown"
              :icon-type="ICON_TYPE.MENU"
            />
          </StitchTooltip>

          <!-- Style Status Action form -->
          <FormGenerator
            :show-form-generator="showStyleStatusActionForm"
            :form-configuration="styleStatusActionFormConfiguration"
            :additional-data="item"
            :original-data="actionConfirmationFormData"
            :default-data="styleStatusActionDefaultFormData"
            :options="styleStatusActionReasons"
            :is-creation="true"
            @form-close="onStyleStatusActionFormClose"
            @form-complete="completeStyleStatusActionForm"
          />
        </div>
      </div>
      <table class="detail-header__table">
        <tr v-if="item.plmCode">
          <td>PLM Code</td>
          <td>{{ item.plmCode }}</td>
        </tr>
        <tr>
          <td>Info</td>
          <td>
            <div
              v-if="itemMeta.length > 0"
              class="detail-header__meta"
            >
              <ElementTag
                v-for="(value, index) in itemMeta"
                :key="index"
                size="mini"
                type="info"
              >
                {{ value }}
              </ElementTag>
            </div>
            <template v-else>
              No info available
            </template>
          </td>
        </tr>
        <tr>
          <td>Created on</td>
          <td>{{ createdAtFormatted }}</td>
        </tr>
        <template v-if="libraryType === LIBRARY_TYPE.STYLE">
          <tr>
            <td>Comments</td>
            <td class="detail-header__comments">
              <StyleComments
                v-if="item"
                :item="item"
              />
            </td>
          </tr>
          <tr v-if="!isVendorUser">
            <td>Assigned Vendor Company</td>
            <td>
              <template v-if="isLoadingVendor">
                <StitchLoader
                  size="mini"
                  placement="start"
                />
              </template>
              <template v-else>
                <VendorSelector
                  :vendors="vendors"
                  :vendor-company-id="item.vendorCompanyId"
                  @update-vendor="updateStyleVendor"
                />
              </template>
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <template v-if="isLoadingStatus">
                <StitchLoader
                  size="mini"
                  placement="start"
                />
              </template>
              <template v-else>
                <LibraryStatusSelector
                  :item="item"
                  :transitions="directStatusTransitions"
                  @update-status="updateStatusWithoutConfirmation"
                />
              </template>
            </td>
          </tr>
        </template>
      </table>
      <div class="detail-header__actions">
        <StitchDropdown
          :dropdown-items="dropdownItems"
          :append-to-body="false"
          class="detail-header__dropdown"
          :title="`Edit ${libraryType} information`"
          :is-disabled="isVendorUser"
          :disabled-message="disabledMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { DataUtils } from '@/mixins/utils.js'
import { formatToLocaleDateLong } from '@/services/utils'
import { getFormConfig } from '@/services/formUtils'
import StitchTooltip from '@/components/common/StitchTooltip'
import { IMAGE_RESOLUTION } from '@/constants/image'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { ItemShape } from '@/types'
import LibraryStatusSelector from '@/components/library/LibraryStatusSelector.vue'
import VendorSelector from '@/components/library/VendorSelector.vue'
import { ICON_TYPE, STYLE_TYPE } from '@/constants/dropdownOptions'
import {
  ASSET_STATUS,
  REASONS_FOR_CANCELLATION,
  REASONS_FOR_RESUBMITTING,
  CRITICAL_STATUSES,
  CRITICAL_STATUSES_NO_VALIDATION
} from '@/constants/assetStatuses'
import { FORM_NAME } from '@/constants/formName'
import { USER_ROLE } from '@/constants/roleType'

export default {
  name: 'DetailHeader',

  components: {
    StitchTooltip,
    LibraryStatusSelector,
    VendorSelector
  },

  mixins: [DataUtils],

  props: {
    item: ItemShape.loose.isRequired
  },

  emits: ['edit-item', 'delete-item', 'asset-status-action'],

  data () {
    return {
      LIBRARY_TYPE,
      ICON_TYPE,
      ASSET_STATUS,
      USER_ROLE,
      assetStatus: null,
      isLoadingStatus: false,
      isLoadingVendor: false,
      showStyleStatusActionForm: false,
      styleStatusActionDefaultFormData: null,
      styleStatusActionReasons: {},
      disabledMessage:
        'As a vendor user, you are not allowed to perform this action.'
    }
  },

  computed: {
    ...mapGetters(['getItemStatus']),

    ...mapGetters({
      vendors: 'getVendors',
      user: 'getCognitoUserData'
    }),

    /**
     * @returns {object}
     */
    itemDetail () {
      return this.getItemStatus()
    },

    /**
     * @returns {boolean}
     */
    isVendorUser () {
      return this.user.role === USER_ROLE.VENDOR.name
    },

    /**
     * @returns {Array}
     */
    directStatusTransitions () {
      if (!this.itemDetail) {
        return []
      }

      return this.itemDetail.transitions.filter(
        transition =>
          !CRITICAL_STATUSES.includes(transition.value) &&
          transition.label !== this.item.assetStatus
      )
    },

    /**
     * @returns {Array}
     */
    criticalStatusTransitions () {
      if (!this.itemDetail) {
        return []
      }

      return this.itemDetail.transitions
        .filter(transition => CRITICAL_STATUSES.includes(transition.value))
        .map(transition => {
          return {
            name: transition.action,
            disabled: !transition.is_possible,
            method: () =>
              CRITICAL_STATUSES_NO_VALIDATION.includes(transition.value)
                ? this.updateStatusWithoutConfirmation(transition.value)
                : this.openAdvancedTransitionAction(transition),
            // Make sure to not display a message when status is already selected
            ...(transition.label !== this.item.assetStatus
              ? {
                  disabledMessage: [
                    `You cannot change status from ${this.item.assetStatus} to ${transition.label}.`
                  ]
                }
              : {})
          }
        })
    },

    /**
     * @returns {Array}
     */
    dropdownItems () {
      return [
        {
          name: 'Edit Information',
          method: () => this.emitEditItem()
        },
        {
          name: `Delete ${this.libraryType}`,
          styles: [STYLE_TYPE.DANGER],
          method: () => this.emitDeleteItem()
        }
      ]
    },

    /**
     * @returns {object}
     */
    itemMeta () {
      return this.item.meta
    },

    /**
     * @returns {object}
     */
    itemColorRun () {
      return this.getColorRunResized(this.item, IMAGE_RESOLUTION.THUMBNAIL)
    },

    /**
     * @returns {string}
     */
    libraryType () {
      return this.item.libraryType
    },

    /**
     * @returns {string}
     */
    createdAtFormatted () {
      return formatToLocaleDateLong(this.item.createdAt)
    },

    /**
     * @returns {object}
     */
    styleStatusActionFormConfiguration () {
      return getFormConfig(FORM_NAME.STATUS_CHANGE_CONFIRMATION)
    },

    /**
     * @returns {object}
     */
    actionConfirmationFormData () {
      return {
        name: this.item.name,
        plmCode: this.item.plmCode
      }
    }
  },

  async created () {
    if (this.item !== null && this.libraryType === LIBRARY_TYPE.STYLE) {
      await this.initStatuses()

      if (!this.isVendorUser) {
        await this.fetchAssignableVendorsByTeam({ teamId: this.item.groupId })
      }
    }
  },

  beforeDestroy () {
    this.clearItemStatus({ libraryType: this.libraryType })
  },

  methods: {
    ...mapActions([
      'fetchItemAssetStatus',
      'fetchAssignableVendorsByTeam',
      'updateAssetStatus',
      'clearItemStatus',
      'updateVendor'
    ]),

    /**
     *
     */
    async initStatuses () {
      this.isLoadingStatus = true

      this.assetStatus = await this.fetchItemAssetStatus({
        itemId: this.item.id,
        libraryType: this.libraryType
      })

      this.isLoadingStatus = false
    },

    /**
     * @param {string} status
     */
    async updateStatusWithoutConfirmation (status) {
      this.isLoadingStatus = true

      await this.updateAssetStatus({
        id: this.item.id,
        assetStatus: status,
        isDetail: true
      })

      this.isLoadingStatus = false
    },

    /**
     * @param {number} vendorId
     */
    async updateStyleVendor (vendorId) {
      this.isLoadingVendor = true

      await this.updateVendor({
        libraryType: this.libraryType,
        itemId: this.item.id,
        vendorId: vendorId
      })

      this.isLoadingVendor = false
    },

    /**
     * @param {object} transition
     */
    openAdvancedTransitionAction (transition) {
      this.styleStatusActionDefaultFormData = {
        assetStatus: transition.label
      }

      this.styleStatusActionReasons = { changeReasons: [] }

      if (transition.label === ASSET_STATUS.CANCELLED.label) {
        this.styleStatusActionReasons.changeReasons = REASONS_FOR_CANCELLATION
      } else if (transition.label === ASSET_STATUS.RESUBMIT.label) {
        this.styleStatusActionReasons.changeReasons = REASONS_FOR_RESUBMITTING
      }

      this.showStyleStatusActionForm = true
    },

    /**
     */
    emitEditItem () {
      this.$emit('edit-item', { styleId: this.item.id })
    },

    /**
     * @returns {Promise<MessageBoxData>}
     */
    emitDeleteItem () {
      return this.$confirm(
        `This will permanently delete ${this.item.name}. Continue?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          showClose: false
        }
      )
        .then(() => {
          this.$emit('delete-item', {
            id: this.item.id,
            name: this.item.name
          })
        })
        .catch(() => {})
    },

    /**
     *
     */
    onStyleStatusActionFormClose () {
      this.showStyleStatusActionForm = false
    },

    /**
     * @param {number} success
     */
    async completeStyleStatusActionForm (success) {
      if (success) {
        await this.initStatuses()
        this.onStyleStatusActionFormClose()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$meta-margin-left: spacing(1/2) * -1;
$meta-margin-bottom: -1 * spacing(2); // Hides the bottom padding from the last row of wrapped tags, making the whole table look cleaner.
$color-run-width: 40%;
$color-run-height: spacing(24);
$meta-list-name-width: 20%;
$meta-list-value-width: 80%;

.detail-header {
  display: flex;
  width: 100%;
  margin-bottom: 0;
}

.detail-header__color-run {
  @include flex-center;

  width: $color-run-width;
  min-width: spacing(30);
  max-width: spacing(60);
  padding: spacing(2);

  > * {
    max-height: $color-run-height;
  }
}

.detail-header__info {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: spacing(2);
  overflow: hidden;
}

.detail-header__title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: spacing(2);

  /deep/ .el-tooltip {
    display: grid;
  }

  h1 {
    @include truncate-lines(1);

    margin-right: spacing(1);
    color: $grey-dark;
    line-height: 1.2;
  }
}

.detail-header__meta {
  margin-bottom: $meta-margin-bottom;
  margin-left: $meta-margin-left;

  > * {
    margin: 0 spacing(1/2) spacing(2);
  }
}

.detail-header__dropdown {
  display: inline-flex;
  margin-bottom: spacing(2);

  /deep/ .el-button {
    padding: 0;
  }
}

.detail-header__actions {
  display: flex;
  align-items: center;

  > * {
    margin-right: spacing(4);
  }
}

.detail-header__table {
  width: 100%;
  margin-bottom: spacing(1);

  tr {
    display: flex;
    margin-bottom: spacing(2);
  }

  td {
    padding: spacing(1) 0;
  }

  td + td {
    margin-left: spacing(1);
  }

  td:first-of-type {
    width: $meta-list-name-width;
    color: $grey;
  }

  td:last-of-type {
    width: $meta-list-value-width;
    color: $grey-light;
  }
}

.detail-header__comments {
  /deep/ .el-button {
    padding: 0;
  }
}
</style>
