<template>
  <header class="the-header">
    <GroupsControl v-if="hasPermissions && visibleInCurrentPage" />
    <router-link
      v-else-if="visibleInCurrentPage"
      :to="{ path: '/library/' }"
      class="the-header__logo"
    >
      <h2 class="the-header__logo-text">
        Design<span>HUB</span>
      </h2>
    </router-link>
    <h2
      v-else
      class="the-header__logo-text"
    >
      {{ $route.meta.title }}
    </h2>
    <div class="the-header__right">
      <ElementPopover
        v-if="hasPermissions"
        title="Notifications"
        trigger="click"
        :append-to-body="false"
        :visible-arrow="false"
        placement="bottom-end"
        popper-class="the-header__notifications"
        width="550"
      >
        <ElementBadge
          id="the-header__notification-count"
          slot="reference"
          :value="unseenNotificationsCount"
          :hidden="unseenNotificationsCount === 0 || !badgeVisibility"
          class="item"
        >
          <ElementButton
            type="text"
            @click="openNotificationsPopover"
          >
            <i class="el-icon-bell" />
          </ElementButton>
        </ElementBadge>
        <div v-if="notifications.length === 0">
          <div class="the-header__notification-empty">
            <i class="el-icon-wind-power" />
            <h3>There's nothing to see.</h3>
          </div>
        </div>
        <div
          v-else
          v-infinite-scroll="fetchNextNotifications"
          infinite-scroll-distance="500"
          :infinite-scroll-disabled="hasReachedEnd"
        >
          <Notifications :notifications="notifications" />
          <StitchLoader v-if="isLoadingNotifications" />
        </div>
      </ElementPopover>

      <ElementDropdown>
        <ElementButton type="text">
          <i class="el-icon-setting" />
        </ElementButton>
        <ElementDropdownMenu
          slot="dropdown"
          :append-to-body="false"
        >
          <ElementDropdownItem class="the-header__user-details">
            <span class="the-header__user-details-email">{{ user.email }}</span>
            <br>
            <span class="the-header__user-details-role">
              {{ USER_ROLE[user.role.toUpperCase()].displayName }}
              {{ user.is_hub_admin ? `- Admin` : `` }}
              {{
                isVendorUser && user.vendor_company
                  ? `- ${user.vendor_company.name}`
                  : ``
              }}
            </span>
          </ElementDropdownItem>
          <ElementDropdownItem
            v-if="!isVendorUser"
            ref="viewMembers"
            divided
            @click.native="openUserAccessManager"
          >
            View Members
          </ElementDropdownItem>
          <ElementDropdownItem
            v-if="hasPermissions"
            data-testid="notificationsSettingsButton"
            @click.native="openNotificationsSettings"
          >
            Settings
          </ElementDropdownItem>
          <ElementDropdownItem
            class="the-header__logout"
            divided
            @click.native="logout(user, $hubApiBaseUrl, $rendersApiBaseUrl)"
          >
            Logout
          </ElementDropdownItem>
        </ElementDropdownMenu>
      </ElementDropdown>
    </div>

    <StitchDialog
      :show-close="false"
      :visible.sync="showUserAccessManager"
      append-to-body
      center
    >
      <HeaderAccessManager
        :group="selectedGroup"
        :members="members"
        class="header-access-manager__content"
      />
      <div
        v-if="isLoadingUserAccessManager"
        class="header-access-manager__loader"
      >
        <StitchLoader />
      </div>
      <ElementButton
        slot="footer"
        class="the-header__dialog-button"
        plain
        type="primary"
        @click="showUserAccessManager = false"
      >
        Done
      </ElementButton>
    </StitchDialog>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GroupsControl from '../GroupsControl'
import HeaderAccessManager from './components/HeaderAccessManager'
import Notifications from './components/notifications/index.vue'
import { USER_ROLE } from '@/constants/roleType'
import { DataUtils } from '@/mixins/utils'
import { FORM_NAME } from '@/constants/formName'
import { logout } from '@/services/utils'
import { ICON_TYPE } from '@/constants/dropdownOptions'
import _debounce from 'lodash/debounce'
import StitchLoader from '@/components/common/StitchLoader.vue'

export default {
  name: 'TheHeader',

  components: {
    StitchLoader,
    GroupsControl,
    HeaderAccessManager,
    Notifications
  },

  mixins: [DataUtils],

  data: () => ({
    USER_ROLE,
    FORM_NAME,
    ICON_TYPE,
    showWorkspaces: false,
    showUserAccessManager: false,
    isLoadingUserAccessManager: false,
    isLoadingNotifications: false,
    showNotificationsSettings: false,
    newInviteeList: [],
    invitationFilters: {
      groupId: null,
      roleId: null,
      search: ''
    }
  }),

  computed: {
    ...mapGetters({
      user: 'getCognitoUserData',
      activeTeam: 'getActiveTeam',
      notifications: 'getNotifications',
      unseenNotificationsCount: 'getUnseenNotificationsCount',
      totalNotificationsCount: 'getNotificationsCount',
      currentNotificationsPage: 'getCurrentNotificationsPage',
      badgeVisibility: 'getNotificationsBadgeVisibility',
      members: 'getMentionableUsers'
    }),

    /**
     * @returns {boolean}
     */
    hasReachedEnd () {
      return this.notifications.length === this.totalNotificationsCount
    },

    /**
     * @returns {boolean}
     */
    hasPermissions () {
      return Boolean(this.user.teams && this.user.teams.length > 0)
    },

    /**
     * @returns {boolean}
     */
    isVendorUser () {
      return this.user.role === USER_ROLE.VENDOR.name
    },

    /**
     * @returns {number}
     */
    itemId () {
      return this.getParamId()
    },

    /**
     * @returns {object}
     */
    selectedGroup () {
      return this.activeTeam
    },

    /**
     * @returns {boolean}
     */
    visibleInCurrentPage () {
      return (
        this.$route.name !== 'Settings' && this.$route.name !== 'AdminTools'
      )
    }
  },

  async mounted () {
    this.isLoadingNotifications = true
    await this.fetchNotifications()
    this.isLoadingNotifications = false
  },

  methods: {
    ...mapActions([
      'fetchNotifications',
      'changeBadgeVisibility',
      'fetchMentionableUsers',
      'markAllNotificationsSeen'
    ]),

    /**
     */
    fetchNextNotifications: _debounce(
      /**
       */
      async function () {
        if (!this.hasReachedEnd) {
          this.isLoadingNotifications = true
          await this.fetchNotifications({ page: this.currentNotificationsPage })
          this.isLoadingNotifications = false
        }
      },
      400
    ),

    /**
     */
    async openNotificationsPopover () {
      if (this.unseenNotificationsCount > 0) {
        await this.markAllNotificationsSeen()
      }

      this.changeBadgeVisibility({ visible: false })
    },

    /**
     */
    openNotificationsSettings () {
      this.$router.push({ name: 'Settings' })
    },

    /**
     */
    async openUserAccessManager () {
      this.invitationFilters.groupId = this.activeTeam.id

      this.isLoadingUserAccessManager = true
      this.showUserAccessManager = true

      await this.fetchMentionableUsers({
        teamId: this.activeTeam.id
      })

      this.isLoadingUserAccessManager = false
    },

    /**
     */
    logout: logout
  }
}
</script>

<style lang="scss" scoped>
$header-access-manager-width: 100%;
$dialog-button-width: 200px;
$active-workspace-margin: 10px;
$logo-size: 32px;
$header-right-margin-left: 15px;
$header-right-font-size: 20px;

.the-header {
  position: relative;
  z-index: $z-index-layer-1;
  display: flex;
  align-items: center;
  width: 100%;
  height: $main-header-height;
  padding: spacing(1) spacing(4);
  background-color: $white;
  box-shadow: $shadow-default;
}

.the-header__workspace {
  display: flex;
  align-items: center;
}

.the-header__workspace .the-header__workspace-logo--active {
  width: $logo-size;
  height: $logo-size;
  margin-left: $active-workspace-margin;
}

.the-header__workspace-item {
  display: flex;
  align-items: center;
}

.the-header__workspace .the-header__workspace-list-item-logo {
  width: $logo-size;
  height: $logo-size;
  margin-right: $active-workspace-margin;
}

.the-header__logo-text {
  @include position-absolute(center, center);

  line-height: 1;
  transition: color $transition;

  span {
    font-weight: $font-weight-bold;
  }
}

.the-header__logo:hover .the-header__logo-text {
  color: $blue;
}

.the-header__right {
  margin-left: auto;
}

.the-header__right i {
  margin-left: $header-right-margin-left;
  font-weight: $font-weight-bold;
  font-size: $header-right-font-size;
}

.the-header__right .el-dropdown-menu__item {
  white-space: nowrap;
}

.the-header__user-details {
  line-height: 12px;
}

.the-header__user-details:hover {
  color: inherit !important;
  background-color: transparent !important;
  cursor: unset;
}

.the-header__user-details:focus {
  outline: none;
  box-shadow: none;
  cursor: unset;
}

.the-header__user-details-email {
  position: relative;
}

.the-header__user-details-role {
  position: relative;
  color: $grey-light;
  font-weight: $font-weight-medium;
  font-size: spacing(1.2);
}

.header-access-manager__loader {
  @include position-absolute(center);

  width: 100%;
  height: 100%;
  background-color: $white-overlay;
  transition: opacity $transition-duration-2;
}

.header-access-manager__content {
  width: $header-access-manager-width;
}

.the-header__dialog-button {
  width: $dialog-button-width;
}

.the-header__logout {
  &,
  &:hover {
    color: $red;
  }
}

.the-header__notification-empty {
  margin: spacing(10) 0;
  text-align: center;
}

.the-header__notification-empty i {
  margin-bottom: spacing(3/2);
  font-size: spacing(6);
  opacity: 0.5;
}
</style>

<style lang="scss">
$notification-badge-size: 10px;
$notification-badge-top-position: 12px;
$popover-padding: 8px 8px 0 12px;

.el-badge {
  vertical-align: unset;
}

.el-popover__title {
  padding: $popover-padding;
  color: $grey-dark;
  font-weight: $font-weight-semibold;
  font-size: spacing(3);
  text-transform: capitalize;
}

.the-header__notifications {
  max-height: spacing(56);
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: $shadow-default;
}

#the-header__notification-count .el-badge__content {
  top: $notification-badge-top-position;
  font-size: $notification-badge-size;
}
</style>
