export const COMPANIES = Object.freeze([
  {
    name: 'PVH',
    link: 'https://hub.hub3d.pvh.com/',
    verticalLogo: require('@/assets/companies/pvh/pvh-logo.svg'),
    horizontalLogo: require('@/assets/companies/pvh/pvh-logo-horizontal.svg')
  },
  {
    name: 'Scotch and Soda',
    link: 'https://hub.beta.hub3d.pvh.com/',
    verticalLogo: require('@/assets/companies/scotch-and-soda/scotch-and-soda-logo.svg'),
    horizontalLogo: require('@/assets/companies/scotch-and-soda/scotch-and-soda-logo-horizontal.svg')
  },
  {
    name: 'Creation Fields',
    link: 'https://hub.creationfields.hub3d.pvh.com/',
    verticalLogo: require('@/assets/companies/creation-fields/creation-fields-logo.svg'),
    horizontalLogo: require('@/assets/companies/creation-fields/creation-fields-logo-horizontal.png')
  }
])
