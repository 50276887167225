import serverAPI from '@/services/serverAPI'
import { LIBRARY_TYPE } from '@/constants/libraryType'
import { UPLOAD_STATUS } from '@/constants/loadingStatus'
import { SORT_QUERY } from '@/constants/sortOption'
import { TRACKER_OBJECTS, TRACKER_EVENTS } from '@/constants/tracker'
import { FILE_TYPES } from '@/constants/fileTypes'
import {
  ASSET_STATUS,
  REASONS_FOR_CANCELLATION,
  REASONS_FOR_RESUBMITTING
} from '@/constants/assetStatuses'
import Utils from '@/services/utils'
import FeatureFlags from '@/services/featureFlags'
import { META_TYPES } from '@/constants/roleType'

// ////////////////////////////////////////////////////
// Helpers
// ////////////////////////////////////////////////////
/**
 * @param   {Array}  newFiles
 * @param   {Array}  oldFiles
 *
 * @returns {object}
 */
const getActionsOnFiles = (newFiles = [], oldFiles = []) => {
  const filesToRemove = oldFiles.filter(
    oldFile => !newFiles.find(newFile => newFile.id === oldFile.id)
  )
  const filesToKeep = newFiles.filter(newFile =>
    oldFiles.find(oldFile => oldFile.id === newFile.id)
  )
  filesToKeep.forEach(file => {
    const oldDescription = oldFiles.find(
      oldFile => oldFile.id === file.id
    ).description

    file.descriptionChanged = file.description !== oldDescription
  })

  const filesToAdd = newFiles.filter(
    newFile => !oldFiles.find(oldFile => oldFile.id === newFile.id)
  )

  return {
    filesToRemove: filesToRemove || [],
    filesToKeep: filesToKeep || [],
    filesToAdd: filesToAdd || []
  }
}

/**
 * @param   {string} libraryType
 *
 * @returns {string}
 */
const getTrackerObject = libraryType => {
  return libraryType === LIBRARY_TYPE.STYLE
    ? TRACKER_OBJECTS.STYLE
    : TRACKER_OBJECTS.LIBRARY_ITEM
}

/**
 * @param   {string|null} [property]
 * @param   {boolean}     [allowNull=false]
 *
 * @returns {string|null}
 */
const processProperty = (property, allowNull = false) => {
  if (allowNull === false && property === null) {
    return undefined
  }

  if (property === undefined) {
    return undefined
  }

  // Change empty strings to null (unsets the value in the backend)
  if (property === '') {
    return null
  }

  return property
}

/**
 * @param   {object} payload
 *
 * @returns {object}
 */
const processStyleFields = payload => {
  // list of static attributes, and the rest are flexible attributes
  const {
    groupId,
    name,
    tags,
    priceId,
    seasonId,
    vendorCompanyId,
    isMain,
    ...flexibleAttributes
  } = payload
  const processedFlexibleAttributes = {}

  for (const attribute in flexibleAttributes) {
    const processedProperty = processProperty(flexibleAttributes[attribute])

    if (processedProperty && attribute !== META_TYPES.ROLE) {
      processedFlexibleAttributes[attribute] = processedProperty
    }
  }

  return {
    price_id: processProperty(payload.priceId),
    attributes: processedFlexibleAttributes
  }
}

// ////////////////////////////////////////////////////
// Actions
// ////////////////////////////////////////////////////

/**
 * @param   {Store}         context
 * @param   {Store.commit}  context.commit
 * @param   {Store.getters} context.getters
 * @param   {object}        payload
 * @param   {string}        payload.libraryType
 *
 * @returns {object}
 */
const fetchFilteredItems = async (
  { commit, getters },
  { libraryType } = {}
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  const activeWorkspace = getters.getActiveWorkspaceCode

  const search = getters.getSearch({ libraryType })
  const sorting = getters.getSortingItems({ libraryType })
  const sortingParameters = SORT_QUERY[sorting]
  const paging = getters.getPagingItems({ libraryType })
  const page = paging.currentPage
  const limit = paging.itemsLimit
  const itemsCount = paging.itemsCount
  let filters = JSON.parse(
    JSON.stringify(getters.getAppliedFilters({ libraryType }))
  )

  // For Browzwear API
  // Force returned items to be u3ma files
  const isBrowzwearAPI = getters.getBrowzwearAPI

  // backend is expecting tag_id, although it is a list of tags named "tags"
  // check itemAPI fetchItems()
  const { tags, ...remainingFilters } = filters

  if (isBrowzwearAPI) {
    filters = {
      ...remainingFilters,
      tag_id: tags,
      file_type: ['u3ma']
    }
  }

  const response = await serverAPI.fetchItems({
    search,
    filters,
    page,
    limit,
    sortingParameters,
    libraryType,
    activeWorkspace
  })

  if (response) {
    const { count, next, results: items } = response

    if (itemsCount !== count) {
      commit('setPagingItemsCount', {
        itemsCount: count,
        libraryType
      })
    }

    if (next === null) {
      commit('setHasItemsReachedEnd', {
        hasItemsReachedEnd: true,
        libraryType
      })
    }

    commit('setItems', { items, libraryType })
  }

  return response
}

/**
 * @param   {Store}          context
 * @param   {Store.commit}   context.commit
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 * @param   {number}         payload.itemId
 * @param   {string}         payload.libraryType
 *
 * @returns {object}
 */
const fetchItemDetail = async (
  { commit, getters, dispatch },
  { itemId, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  let itemDetail = getters.getItemById({ id: itemId, libraryType })

  // Styles demand more data than available by default
  if (
    !itemDetail ||
    libraryType === LIBRARY_TYPE.STYLE ||
    libraryType === LIBRARY_TYPE.BLOCK
  ) {
    itemDetail = await dispatch('refreshItem', { itemId, libraryType })
  }

  commit('setItemDetail', { item: itemDetail, libraryType })

  return itemDetail
}

/**
 * @param   {Store}          context
 * @param   {Store.commit}   context.commit
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 * @param   {number}         payload.itemId
 * @param   {string}         payload.libraryType
 *
 * @returns {object}
 */
const fetchItemAssetStatus = async (
  { commit, getters },
  { itemId, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  const activeWorkspace = getters.getActiveWorkspaceCode

  const status = await serverAPI.fetchAssetStatus({
    id: itemId,
    libraryType,
    activeWorkspace
  })

  status.transitions = status.transitions.map(transition => {
    return {
      ...transition,
      action: ASSET_STATUS[transition.value.toUpperCase()].action
    }
  })

  commit('setItemStatus', { status, libraryType })

  return status
}

/**
 * @param   {Store}          context
 * @param   {Store.commit}   context.commit
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 *
 * @returns {object}
 */
const updateAssetStatus = async ({ commit, getters, dispatch }, payload) => {
  const data = payload.new ?? payload

  const libraryType = data.libraryType || getters.getActiveLibraryType
  const activeWorkspace = getters.getActiveWorkspaceCode

  const { assetStatus, changeReasonId, isDetail } = data
  let changeReason = null

  if (
    [ASSET_STATUS.RESUBMIT.label, ASSET_STATUS.CANCELLED.label].includes(
      assetStatus
    )
  ) {
    const reasons =
      assetStatus === ASSET_STATUS.RESUBMIT.label
        ? REASONS_FOR_RESUBMITTING
        : REASONS_FOR_CANCELLATION
    changeReason = reasons.find(reason => reason.id === changeReasonId)?.name
  }

  // Specific case for status = 'Approved for DAM'
  if (assetStatus === ASSET_STATUS.APPROVED_FOR_DAM.label) {
    data.assetStatus = ASSET_STATUS.APPROVED_FOR_DAM.value
  }

  const processedPayload = { ...data, changeReason, libraryType }
  const itemId = processedPayload.id

  await serverAPI.updateAssetStatus({
    ...processedPayload,
    activeWorkspace
  })

  await dispatch('refreshItem', {
    itemId,
    libraryType
  })

  if (isDetail) {
    return await dispatch('fetchItemAssetStatus', {
      itemId,
      libraryType
    })
  }
}

/**
 * @param   {Store}          context
 * @param   {Store.commit}   context.commit
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 *
 * @returns {object}
 */
const addItem = async ({ commit, getters, dispatch }, payload) => {
  const libraryType = payload.libraryType || getters.getActiveLibraryType
  const activeWorkspace = getters.getActiveWorkspaceCode

  const appliedFilters = JSON.parse(
    JSON.stringify(getters.getAppliedFilters({ libraryType }))
  )

  const processedPayload = processFormResult({ ...payload, libraryType })

  const item = await serverAPI.addItem({
    ...processedPayload,
    activeWorkspace
  })

  commit('addItem', {
    item,
    libraryType
  })

  if (libraryType === LIBRARY_TYPE.STYLE) {
    await dispatch('overrideFiltersFromStyle', {
      appliedFilters,
      itemId: item.id,
      libraryType
    })
  }

  const refreshedItem = await dispatch('processPostChangeItem', {
    ...payload,
    itemId: item.id,
    libraryType
  })

  dispatch('trackEvent', {
    object: getTrackerObject(libraryType),
    action: TRACKER_EVENTS.ADDED,
    item: refreshedItem,
    libraryType
  })

  return refreshedItem
}

/**
 * @param   {object} payload
 *
 * @returns {object}
 */
const processFormResult = payload => {
  const styleFields =
    payload.libraryType === LIBRARY_TYPE.STYLE
      ? processStyleFields(payload.new)
      : {}

  return {
    // All
    ...(payload.itemId
      ? {
          id: processProperty(payload.itemId)
        }
      : {}),
    libraryType: processProperty(payload.libraryType),
    name: processProperty(payload.new.name),
    // Trims & Fabrics
    plm_code: processProperty(payload.new.plmCode, true),
    // Blocks
    division_id: processProperty(payload.new.divisionId),
    product_group_id: processProperty(payload.new.productGroupId),
    type: processProperty(payload.new.type),
    // Trims
    trim_material_subtype_id: processProperty(
      payload.new.trimMaterialSubtypeId
    ),
    // Fabrics
    composition: processProperty(payload.new.composition),
    fabric_material_subtype_id: processProperty(
      payload.new.fabricMaterialSubtypeId
    ),
    sustainability_rating_id: processProperty(
      payload.new.sustainabilityRatingId,
      true
    ),
    // Style & Block
    render_engine: processProperty(payload.new.renderEngine),
    season_id: processProperty(payload.new.seasonId),
    group_id: processProperty(payload.new.groupId),
    // Styles
    vendor_company_id: processProperty(payload.new.vendorCompanyId),
    ...styleFields
  }
}

/**
 * @param   {Store}          context
 * @param   {Store.commit}   context.commit
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 *
 * @returns {object}
 */
const updateItem = async ({ commit, getters, dispatch }, payload) => {
  const libraryType = payload.libraryType || getters.getActiveLibraryType
  const activeWorkspace = getters.getActiveWorkspaceCode
  const processedPayload = processFormResult({ ...payload, libraryType })
  const item = await serverAPI.updateItem({
    ...processedPayload,
    activeWorkspace
  })

  commit('updateItem', {
    item,
    libraryType
  })

  const refreshedItem = await dispatch('processPostChangeItem', {
    ...payload,
    itemId: item.id,
    libraryType
  })

  dispatch('trackEvent', {
    object: getTrackerObject(libraryType),
    action: TRACKER_EVENTS.UPDATED,
    item: refreshedItem,
    libraryType
  })

  return refreshedItem
}

/**
 * @param {Vuex.Store} store
 * @param {object}     payload
 */
const updateVendor = async (store, payload) => {
  const { itemId, vendorId, libraryType } = payload

  await serverAPI.updateVendor(itemId, vendorId)

  await store.dispatch('refreshItem', {
    itemId,
    libraryType
  })
}

/**
 * @param   {Store}          context
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 *
 * @returns {object}
 */
const processPostChangeItem = async ({ getters, dispatch }, payload) => {
  const libraryType = payload.libraryType
  const itemId = payload.itemId

  payload.refreshItem = false

  await dispatch('updateItemDivisions', {
    ...payload
  })

  await dispatch('updateItemTags', {
    ...payload
  })

  await dispatch('updateOrInsertFiles', {
    ...payload
  })

  return await dispatch('refreshItem', {
    itemId,
    libraryType
  })
}

/**
 * @param   {Store}         context
 * @param   {Store.commit}  context.commit
 * @param   {Store.getters} context.getters
 * @param   {object}        payload
 * @param   {number}        payload.id
 * @param   {string}        payload.libraryType
 * @returns {null}
 */
const deleteItem = async (
  { commit, dispatch, getters },
  { id, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  const itemDetail = getters.getItemDetail({ id, libraryType })
  const activeWorkspace = getters.getActiveWorkspaceCode

  dispatch('trackEvent', {
    object: getTrackerObject(libraryType),
    action: TRACKER_EVENTS.DELETED,
    item: itemDetail || undefined,
    value: id,
    libraryType
  })

  await serverAPI.deleteItem({
    id,
    libraryType,
    activeWorkspace
  })

  commit('deleteItem', {
    id,
    libraryType
  })

  if (itemDetail && itemDetail.id === id) {
    commit('clearItemDetail', { libraryType })
  }

  return null
}

/**
 * @param   {Store}          context
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 *
 * @returns {object}
 */
const updateItemDivisions = async ({ getters, dispatch }, payload) => {
  const libraryType = payload.libraryType || getters.getActiveLibraryType
  const itemId = payload.itemId

  const refreshItem = payload.refreshItem !== false
  const oldDivisionIds = (payload.current && payload.current.divisionIds) || []
  const newDivisionIds = (payload.new && payload.new.divisionIds) || []

  if (oldDivisionIds.length > 0 || newDivisionIds.length > 0) {
    const divisionIdsToRemove = oldDivisionIds.filter(
      id => !newDivisionIds.includes(id)
    )

    if (divisionIdsToRemove && divisionIdsToRemove.length > 0) {
      await dispatch('unassignItemDivisions', {
        itemId,
        divisionIds: divisionIdsToRemove,
        libraryType
      })
    }

    const divisionIdsToAdd = newDivisionIds.filter(
      id => !oldDivisionIds.includes(id)
    )

    if (divisionIdsToAdd) {
      await dispatch('assignItemDivisions', {
        itemId,
        divisionIds: divisionIdsToAdd,
        libraryType
      })
    }
  }

  if (refreshItem) {
    return await dispatch('refreshItem', {
      itemId,
      libraryType
    })
  }
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {object}       payload
 * @param {number}       payload.itemId
 * @param {number[]}     payload.divisionIds
 * @param {string}       payload.libraryType
 */
const assignItemDivisions = (
  { commit, getters },
  { itemId, divisionIds, libraryType }
) => {
  const activeWorkspace = getters.getActiveWorkspaceCode

  for (const divisionId of divisionIds) {
    serverAPI.assignItemToDivision({
      id: itemId,
      divisionId,
      libraryType,
      activeWorkspace
    })
  }
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {object}       payload
 * @param {number}       payload.itemId
 * @param {number[]}     payload.divisionIds
 * @param {string}       payload.libraryType
 */
const unassignItemDivisions = (
  { commit, getters },
  { itemId, divisionIds, libraryType }
) => {
  const activeWorkspace = getters.getActiveWorkspaceCode

  for (const divisionId of divisionIds) {
    serverAPI.unassignItemToDivision({
      id: itemId,
      divisionId,
      libraryType,
      activeWorkspace
    })
  }
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {object}        payload.sorting
 * @param {string}        payload.libraryType
 */
const setSortingItems = ({ commit, getters }, { sorting, libraryType }) => {
  libraryType = libraryType || getters.getActiveLibraryType

  commit('setSortingItems', { sorting, libraryType })
  commit('resetItems', { libraryType })
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {string}        payload.libraryType
 */
const incrementPagingItems = ({ commit, getters }, { libraryType } = {}) => {
  libraryType = libraryType || getters.getActiveLibraryType

  commit('incrementPagingItems', { libraryType })
}

/**
 * @param {Store}         context
 * @param {Store.commit}  context.commit
 * @param {Store.getters} context.getters
 * @param {object}        payload
 * @param {string}        payload.libraryType
 */
const resetItems = ({ commit, getters }, { libraryType } = {}) => {
  libraryType = libraryType || getters.getActiveLibraryType

  commit('resetItems', { libraryType })
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {string}       activeLibraryType
 */
const setActiveLibraryType = ({ commit }, activeLibraryType) => {
  commit('setActiveLibraryType', activeLibraryType)
}

/**
 * @param   {Store}          context
 * @param   {Store.getters}  context.getters
 * @param   {Store.commit}   context.commit
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 * @param   {number}         payload.itemId
 * @param   {boolean}        payload.refreshItem
 * @param   {object}         payload."new"
 * @param   {object}         payload.current
 * @param   {string}         payload.libraryType
 * @returns {object}
 */
const updateOrInsertFiles = async (
  { getters, commit, dispatch },
  { itemId, refreshItem, new: newPayload, current, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  refreshItem = refreshItem !== false
  const fileTypes = Object.values(FILE_TYPES)

  try {
    commit('setItemUploadStatus', {
      id: itemId,
      status: UPLOAD_STATUS.PENDING,
      libraryType
    })

    for (const fileType of fileTypes) {
      const files = getActionsOnFiles(
        newPayload[fileType],
        current[fileType] ? current[fileType] : []
      )

      await dispatch('updateItemFiles', {
        itemId,
        fileType,
        files,
        libraryType
      })
    }

    commit('setItemUploadStatus', {
      id: itemId,
      status: UPLOAD_STATUS.COMPLETED,
      libraryType
    })
  } catch (error) {
    commit('setItemUploadStatus', {
      id: itemId,
      status: UPLOAD_STATUS.FAILED,
      libraryType
    })

    throw error
  }

  if (refreshItem) {
    const refreshedItem = await dispatch('refreshItem', {
      itemId,
      libraryType
    })

    dispatch('trackEvent', {
      object: getTrackerObject(libraryType),
      action: TRACKER_EVENTS.UPDATED,
      item: refreshedItem,
      value: itemId,
      libraryType
    })

    return refreshedItem
  }
}

/**
 * @param   {Store}          context
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 * @param   {number}         payload.itemId
 * @param   {object}         payload."new"
 * @param   {object}         payload.current
 * @param   {string}         payload.libraryType
 * @returns {object}
 */
const updateItemFileDescriptions = async (
  { getters, dispatch },
  { itemId, new: newPayload, current, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  const files = getActionsOnFiles(newPayload.model3D, current.model3D)

  for (const file of files.filesToKeep) {
    if (!file.description || !file.descriptionChanged) {
      continue
    }

    await dispatch('updateItemFileDescription', {
      fileId: file.id,
      fileDescription: file.description,
      libraryType
    })
  }

  return await dispatch('refreshItem', {
    itemId,
    libraryType
  })
}

/**
 * @param   {Store}         context
 * @param   {Store.commit}  context.commit
 * @param   {Store.getters} context.getters
 * @param   {object}        payload
 * @param   {number}        payload.itemId
 * @param   {string}        payload.libraryType
 * @returns {object}
 */
const refreshItem = async ({ commit, getters }, { itemId, libraryType }) => {
  const activeWorkspace = getters.getActiveWorkspaceCode

  const item = await serverAPI.fetchItemById({
    id: itemId,
    libraryType,
    activeWorkspace
  })

  commit('updateItem', {
    item,
    libraryType
  })
  const updatedItem = getters.getItemById({ id: itemId, libraryType })
  const itemDetail = getters.getItemDetail({ libraryType })

  if (itemDetail !== null && itemDetail.id === itemId) {
    commit('setItemDetail', { item: updatedItem, libraryType })
  }

  return updatedItem
}

/**
 * @param {Store}          context
 * @param {Store.getters}  context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {number}         payload.blockId
 * @param {number}         payload.versionId
 * @param {string}         payload.libraryType
 */
const setBlockMainVersion = async (
  { getters, dispatch },
  { blockId, versionId, libraryType }
) => {
  libraryType = LIBRARY_TYPE.BLOCK

  await serverAPI.updateBlockVersion({
    blockId,
    versionId,
    isMain: true
  })

  await dispatch('refreshItem', {
    itemId: blockId,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.commit}   context.commit
 * @param {Store.getters}  context.getters
 * @param {Store.dispatch} context.dispatch
 * @param {object}         payload
 * @param {object}         payload."new"
 * @param {string}         payload.libraryType
 */
const updateBlockVersion = async (
  { commit, getters, dispatch },
  { new: newPayload, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType
  const { blockId, versionId, name, file, isMain } = newPayload

  commit('setItemUploadStatus', {
    id: blockId,
    status: UPLOAD_STATUS.PENDING,
    libraryType
  })

  await serverAPI.updateBlockVersion({
    blockId,
    versionId,
    name,
    file,
    isMain
  })

  commit('setItemUploadStatus', {
    id: blockId,
    status: UPLOAD_STATUS.COMPLETED,
    libraryType
  })

  await dispatch('refreshItem', {
    itemId: blockId,
    libraryType
  })
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {object}       payload
 * @param {string}       payload.libraryType
 */
const clearItemDetail = ({ commit }, { libraryType }) => {
  commit('clearItemDetail', { libraryType })
}

/**
 * @param {Store}        context
 * @param {Store.commit} context.commit
 * @param {object}       payload
 * @param {string}       payload.libraryType
 */
const clearItemStatus = ({ commit }, { libraryType }) => {
  commit('clearItemStatus', { libraryType })
}

/**
 * @param   {Store}          context
 * @param   {Store.commit}   context.commit
 * @param   {Store.getters}  context.getters
 * @param   {Store.dispatch} context.dispatch
 * @param   {object}         payload
 * @param   {number}         payload.itemId
 * @param   {object}         payload."new"
 * @param   {string}         payload.libraryType
 *
 * @returns {object}
 */
const addBlockVersion = async (
  { commit, getters, dispatch },
  { itemId, new: newPayload, libraryType }
) => {
  libraryType = LIBRARY_TYPE.BLOCK

  const { blockId = itemId, model3D, renderEngine, isMain = false } = newPayload

  const file = model3D && model3D.length > 0 ? model3D[0].file : null
  const contributor = getters.getCognitoUserData
  let name = newPayload.name || Utils.getDescriptionFromFileName(file.name)

  if (name === '') {
    name = 'temporary name'
  }

  commit('setItemUploadStatus', {
    id: blockId,
    status: UPLOAD_STATUS.PENDING,
    libraryType
  })

  const fakeId = `fakeId-${Math.round(Math.random() * 100)}-${Math.round(
    Math.random() * 100
  )}`

  const engine = FeatureFlags.isVRayOnlyTeam()
    ? 'vray'
    : FeatureFlags.canTeamAccessRenderEngineChoice()
      ? renderEngine
      : 'blender'

  commit('setVersionsUploadInProgress', {
    styleId: blockId,
    fakeId,
    name,
    isMain,
    renderEngine: engine,
    contributor,
    libraryType
  })

  await serverAPI.addBlockVersion({
    blockId,
    file,
    name,
    isMain,
    renderEngine: engine
  })

  commit('deleteVersionsUploadInProgress', {
    styleId: blockId,
    fakeId,
    libraryType
  })

  commit('setItemUploadStatus', {
    id: blockId,
    status: UPLOAD_STATUS.COMPLETED,
    libraryType
  })

  const refreshedItem = await dispatch('refreshItem', {
    itemId: blockId,
    libraryType
  })

  return refreshedItem
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {Store.getters}  context.getters
 * @param {object}         payload
 * @param {number}         payload.blockId
 * @param {number}         payload.versionId
 * @param {string}         payload.libraryType
 */
const deleteBlockVersion = async (
  { dispatch, getters },
  { blockId, versionId, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  await serverAPI.deleteVersion({
    blockId,
    versionId
  })

  await dispatch('refreshItem', {
    itemId: blockId,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {Store.getters}  context.getters
 * @param {object}         payload
 * @param {number}         payload.blockId
 * @param {number}         payload.versionId
 * @param {number}         payload.imageId
 * @param {File}           payload.file
 * @param {boolean}        payload.isThumbnail
 * @param {string}         payload.libraryType
 */
const updateViewImage = async (
  { dispatch, getters },
  { blockId, versionId, imageId, file, isThumbnail, libraryType }
) => {
  libraryType = libraryType || getters.getActiveLibraryType

  await serverAPI.updateBlockViewImage({
    blockId,
    versionId,
    imageId,
    file,
    isThumbnail
  })

  await dispatch('refreshItem', {
    itemId: blockId,
    libraryType
  })
}

/**
 * @param {Store}          context
 * @param {Store.dispatch} context.dispatch
 * @param {Store.getters}  context.getters
 * @param {object}         payload
 * @param {number}         payload.blockId
 * @param {number}         payload.versionId
 * @param {number}         payload.imageId
 * @param {string}         payload.libraryType
 */
const deleteBlockViewImage = async (
  { dispatch, getters },
  { blockId, versionId, imageId, libraryType }
) => {
  libraryType = libraryType || LIBRARY_TYPE.BLOCK

  await serverAPI.deleteBlockViewImage({
    blockId,
    versionId,
    imageId
  })

  await dispatch('refreshItem', {
    itemId: blockId,
    libraryType
  })
}

// ////////////////////////////////////////////////////
// Build object
// ////////////////////////////////////////////////////

const actions = {
  fetchFilteredItems,
  fetchItemDetail,
  clearItemDetail,
  addItem,
  updateItem,
  processPostChangeItem,
  updateOrInsertFiles,
  deleteItem,
  assignItemDivisions,
  updateItemDivisions,
  unassignItemDivisions,
  updateItemFileDescriptions,
  setSortingItems,
  incrementPagingItems,
  resetItems,
  setActiveLibraryType,
  refreshItem,
  setBlockMainVersion,
  addBlockVersion,
  updateViewImage,
  deleteBlockViewImage,
  deleteBlockVersion,
  updateBlockVersion,
  fetchItemAssetStatus,
  updateAssetStatus,
  clearItemStatus,
  updateVendor
}

export default actions
