<template>
  <div class="settings">
    <div class="settings__container">
      <div class="settings--header">
        <h2>Notifications and Activity</h2>
        <ElementButton
          type="primary"
          plain
          class="settings--button-reset"
          :loading="isLoading"
          @click="restoreToDefault"
        >
          Restore to Defaults
        </ElementButton>
      </div>
      <NotificationsSettings :reset="reset" />
    </div>
  </div>
</template>

<script>
import NotificationsSettings from '@/components/layout/TheHeader/components/notifications/components/NotificationsSettings.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Settings',

  components: { NotificationsSettings },

  data () {
    return {
      isLoading: false,
      reset: false
    }
  },

  methods: {
    ...mapActions(['resetNotificationSettings']),

    /**
     *
     */
    restoreToDefault () {
      this.$confirm(
        'Are you sure you want to reset the notifications settings to default?',
        'Info',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
          showClose: false
        }
      )
        .then(async () => {
          this.isLoading = true
          await this.resetNotificationSettings()
          this.reset = true
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
        .finally(() => {
          this.reset = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  height: 100%;
}

.settings__container {
  height: 100%;
  padding: spacing(6) spacing(4);
  overflow-y: scroll;
}

.settings__container::-webkit-scrollbar {
  display: none;
}

.settings__container h2 {
  margin-bottom: spacing(2.5);
}

.settings--header {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin-bottom: spacing(2);
  padding: 0;
}

.settings__card {
  margin-bottom: spacing(4);
  padding: spacing(2);

  h1 {
    margin-bottom: spacing(1);
  }

  p {
    margin-bottom: spacing(2);
  }

  .settings__list-main {
    @include text-title-small;
  }

  .settings__list-sub {
    @include text-body;

    margin: spacing(1) 0;
    padding-left: spacing(2);
    list-style: none;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
